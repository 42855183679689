import { useCallback, useState } from 'react';
import { ApiError, OpenAPI } from 'app/API';

type TCallApiOptions = {
  onError?: (error: any) => void;
  onSuccess?: (response: any) => void;
  dontCatchErrors?: boolean;
};

export function useAPI() {
  const [error, setError] = useState<ApiError | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const API_URL = import.meta.env.VITE_API_URL ?? '';

  OpenAPI.BASE = API_URL ?? '';
  OpenAPI.WITH_CREDENTIALS = true;

  const call = useCallback(async function <T extends { body: any; status: any; url: any }>(
    request: Promise<T>,
    options?: TCallApiOptions,
  ): Promise<T['body']> {
    setIsLoading(true);
    setError(undefined);
    try {
      const response = await request;
      if (response.status === 429 && window.location.pathname !== '/rate-limit') {
        window.location.pathname = '/rate-limit';
        return false;
      }
      if (response.status === 412 && window.location.pathname !== '/account-disabled') {
        window.location.pathname = '/account-disabled';
        return false;
      }
      if (options?.dontCatchErrors) {
        if (options?.onSuccess) {
          options.onSuccess(response.body);
        }
        return response.body;
      }

      if (response.status === 500) {
        console.error('response.body', response.body);
        console.error('response.url', response.url);
        console.error('response.status', response.status);
        console.error('response', response);
        if (!window.location.href.includes('/errors/500')) {
          window.location.href = '/errors/500';
        }
        return;
      }

      if (response.status >= 400) {
        console.error('response.body', response.body);
        console.error('response.url', response.url);
        console.error('response.status', response.status);
        console.error('response', response);
        setError(error);
        if (options?.onError) {
          options.onError(response.body.message);
        }
        return;
      }
      if (options?.onSuccess) {
        options.onSuccess(response.body);
      }
      return response.body;
    } catch (error: any) {
      setError(error);
      if (options?.onError) {
        options.onError(error);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  function dismissError() {
    setError(undefined);
  }

  return { dismissError, error, isLoading, call };
}
